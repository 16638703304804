<template>
  <v-container fluid id="CnabRetornoContainer" class="container-fundo pa-0 pb-1" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">

      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-3 ml-4 ">
        <v-row  >
          <v-col cols="2">
            <span>Cnab Retorno</span>
          </v-col>
          <v-col cols="10">
            <v-tabs id="tabsRetorno"
              v-model="tab"
              background-color="transparent"
              dark
              slider-size="2"
              tabindex="-1"
              slider-color="#D0D0D0"
              active-class="active"
            >
              <v-tab
                class="text-white title-page mt-3 ml-4 " id="tabRetorno"
                tabindex="-1"
              >Importação</v-tab>
              <v-tab
                class="text-white title-page mt-3 ml-4 " id="tabRetorno"
                tabindex="-1"
              >Consulta</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-toolbar-title>
      <!-- Fim Título da página -->

    </v-toolbar-title>
    
    <v-row class="mx-0">
      <CnabRetornoImportacao 
        v-if="tab == 0"/>
      <CnabRetornoConsulta
        v-if="tab == 1"/>
    </v-row>

  </v-container>
  
</template>

<script>
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import CnabRetornoImportacao from "./CnabRetornoImportacao.vue"
import CnabRetornoConsulta from "./CnabRetornoConsulta.vue"

export default {
  name: "CnabRetornoContainer",
  components: {
    CnabRetornoImportacao,
    CnabRetornoConsulta
  },

  data() {
    return {

      loading             : false,
      loading_excluir     : false,
      currentPage: 1,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      COR_SUBTITULO           : COR_SUBTITULO,

      tab : null,

    };
  },

};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif!important;
}

table tr {
  width: 100%;
}

.status_dia, 
.status_baixado,
.status_pago-atraso, 
.status_nao-encontrado, 
.status_msg-banco, 
.status_invalido {
  height: 24px;
  display: block!important;
  white-space: nowrap;
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px ;
  border-radius: 25px;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
.status_legend {
  font-size: 12px !important;
  font-weight: 400!important;
  padding: 2px 2px 2px 12px ;
}

.status_legend:hover {
  opacity: 0.5;
}

.cliente, .empreendimento, .ocorrencia {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O MW */
.ocorrencia {
  max-width: 20ch;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.icon-help {
  margin-top: -2px;
  color:  #FFF;
  opacity: 0.5;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.inputs_header {
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

/* TABELA */
.container-principal {
  max-width: calc(100% - 32px) !important;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }
}
</style>

