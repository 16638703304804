<template>
  <v-container fluid id="CnabRetornoConsulta" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
  
      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title
        class="mt-n2 mb-10 ml-4 pa-0 d-flex flex-column "
        dark
        elevation="0"
      >
        <div class="d-flex align-center justify-space-between " tabindex="-1">
          <div class="d-flex align-center" style="width:100%;">
            <v-col cols="12" sm="2" class="pt-2 px-2 pl-0 pb-0">
              
              <v-select
                v-model="dados.banco_selecionado"
                :items="opcoes_banco"
                item-text="banco_nome"
                item-value="cod_banco"
                label="Banco (Leiaute de arquivo)"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                return-object>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" class="pt-2 px-2 pl-0 pb-0">

              <v-select
              v-model="dados.cedente_selecionado"
                :items="opcoes_cedente"
                item-text="cedente_1"
                item-value="cod_pessoa_conta"
                label="Cedente"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                return-object
                :loading="loading_cedente">
              </v-select>
            </v-col>

            <v-col cols="12" sm="2" class="pt-2 px-2 pl-0 pb-0">

              <v-select
                label="N° arquivo"
                v-model="dados.arquivo_selecionado"
                :items="opcoes_arquivo"
                item-text="arquivo_situacao"
                item-value= "cod_empreendcobrancacnab"
                dense
                class="inputs_header mr-0"
                flat
                dark
                solo-inverted
                @change="exibe_arquivo(dados.arquivo_selecionado.cod_empreendcobrancacnab)"
                return-object
                :loading="loading_arquivo">
              </v-select>
            </v-col>
            <div>
             <div v-show="empreend_nome" class="text-white  text-capitalize descricao" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
              <p class= "descricao-line">Empreendimento: {{empreend_nome}}</p>
              <p class= "descricao-line"><span>Banco: {{nome_banco}}</span><span> - Ag: {{agencia_banco}}</span></p>
              <p class= "descricao-line">Cedente: {{cod_cedente}} - {{nome_cedente}}</p>
            </div>    
            </div>
          </div>
          </div>

          
        

      </v-toolbar-title>
    
    <v-row class="mt-n11 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 ">
        <v-container class="container-principal container-rounded rounded-lg tabela mx-0 px-0">
          <v-card elevation="0" class="pb-0">
            <v-data-table
            :headers="headers"
            :items="dados_cobrancas"
            :loading="loading"
            :items-per-page="-1"
            :height="tableHeight"
            fixed-header
            dense
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            >
              <template v-slot:header>
                  <thead>
                    <tr >
                      <th v-for="h in headers" :key="h.value" :class="h.class" class="pt-0 pb-3">
                        <span>{{h.text}}</span>
                      </th>
                    </tr>
                  </thead>
              </template>

              <template #item="{ item }">
                <tr :style="{'background-color': item.residuo > 0 ? '#FFD0D0' : '#FFFFFF'}" v-show="item.codigo_movimento_retorno != '228'">
                  <td>
                  <!-- Situação -->
                    <v-tooltip top
                      v-if="item.situacao == 'Dia'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_dia">Dia<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que serão baixadas em dia</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Em Atraso'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_pago-atraso">Pago atraso<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que serão baixadas em atraso</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Baixado'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_baixado">Baixado<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que já estão baixadas no sistema</span>
                    </v-tooltip> 
                    <v-tooltip top
                      v-if="item.situacao == 'Não Encontrado'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_nao-encontrado">Não encontrado<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças localizadas no arquivo CNAB, mas não encontradas no sistema</span>
                    </v-tooltip> 
                    <v-tooltip top
                      v-if="item.situacao == 'Inválido' || item.situacao == 'Inval. Antecip' || item.situacao == 'Invál. Rescindi'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_invalido">Inválido<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças inválidas por motivo de erro de leitura, arquivo inválido e/ou dados não permitidos para a cobrança</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Msg. do Banco'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_msg-banco">Msg do banco<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças localizadas no arquivo CNAB como mensagens de operações bancárias</span>
                    </v-tooltip>
                  </td>

                  <!-- Motivo Retorno -->
                  <td>
                    <span class="motivo_retorno">{{ item.codigo_movimento_retorno_descr }}</span>
                  </td>

                  <!-- Código -->
                  <td class="tipoNumero">
                    {{ item.cod_empreendcobranca }}
                  </td>

                  <!-- Via -->
                  <td class="tipoNumero">
                    {{ item.empreendcobr_impressao_via }}
                  </td>

                  <!-- Parcela -->
                  <td >
                    {{ item.parcinicial_parcfinal }}
                  </td>

                  <!-- Tipo -->
                  <td>
                    {{ item.parcela_tipo }}
                  </td>

                  <!-- Dt. Vencimento -->
                  <td>
                    {{ formatDate(item.parcela_dt) }}
                  </td>

                  <!-- Dt. Pagamento -->
                  <td>
                    {{ item.codigo_movimento_retorno == '02' ? '' : formatDate(item.pagto_dt) }}
                  </td>

                  <!-- Valor Parcela -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.parcela_valor) }}
                  </td>

                  <!-- Multa/Juros à pagar -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.multa_juros) }}
                  </td>

                  <!-- Vl à Pagar -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.parcela_a_pagar) }}
                  </td>

                  <!-- Multa/Juros Pago -->
                  <td class="tipoNumero">
                    {{item.codigo_movimento_retorno == '02' ? '' :  formatPrice(item.pagto_valor_multajuros) }}
                  </td>

                  <!-- Valor Pagamento -->
                  <td class="tipoNumero">
                    {{ item.codigo_movimento_retorno == '02' ? '' :  formatPrice(item.pagto_valor) }}
                  </td>

                  <!-- Resíduo -->
                  <td class="tipoNumero residuo">
                    <span class="status_cancelado">
                      {{ item.codigo_movimento_retorno == '02' || item.residuo == null ? '' : formatPrice(item.residuo) }}
                    </span> 
                  </td>

                  <!-- Unidade -->
                  <td>
                    {{ item.unidade }}
                  </td>

                  <!-- N° Contrato -->
                  <td class="tipoNumero">
                    {{ item.empreendvenda_contrato }}
                  </td>

                  <!-- Cliente -->
                  <td>
                    <span class="cliente">{{ item.pessoa_nome }}</span>
                  </td>

                  <!-- Empreendimento -->
                  <td>
                    <span class="empreendimento">{{ item.empreend_nome }}</span>
                  </td>

                  <!-- Num. Proposta -->
                  <td class="tipoNumero">
                    {{ item.cod_empreendvenda}}
                  </td>

                  <!-- Nosso Numero -->
                  <td class="tipoNumero">
                    {{ item.titulo_nossonumero }}
                  </td>
                </tr> 
              </template>
            </v-data-table>
            <!-- FIM TABELA COBRANÇAS -------------------------------------------------------->

            <!-- RODAPE COBRANÇAS -------------------------------------------------------->
            <div class="my-2 ml-4">
              <table class="d-flex justify-end">
                <tbody class="" style="width: 65%">
                  <tr class="text sticky-table-footer-desktop">
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total Parcelas:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_parcelas)}}</span>
                    </td>
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total à Pagar:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_pagar)}}</span>
                    </td>
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total Pago:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_pago)}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

            <!-- LEGENDA COBRANÇAS -------------------------------------------------------->
            <!-- <div class="mt-2 mb-1 ml-4">
              <table class="d-flex justify-start">
                <tbody class="">
                  <tr class="text sticky-table-footer-desktop d-flex mb-2">
                    <td align="left" style="display: flex">
                      <span class="text-left body-2 font-weight-bold mr-3" v-bind:style="{ 'color':COR_SUBTITULO }">Legenda:</span>
                    </td>

                    <td align="left" style="display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_dia">Dia<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que serão baixadas em dia</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_pago-atraso">Pago atraso<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que serão baixadas em atraso</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_baixado">Baixado<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que já estão baixadas no sistema</span>
                      </v-tooltip> 
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_nao-encontrado">Não encontrado<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças localizadas no arquivo CNAB, mas não encontradas no sistema</span>
                      </v-tooltip> 
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_invalido">Inválido<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças inválidas por motivo de erro de leitura, arquivo inválido e/ou dados não permitidos para a cobrança</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_msg-banco">Msg do banco<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças localizadas no arquivo CNAB como mensagens de operações bancárias</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div> -->
            </v-card>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    </v-container>
  
</template>

<script>
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import { formatDate, formatPrice } from "../../../services/funcoes";
import { API, API_BLOB } from "../../../services/API";
//import {CNABController} from "\\viscom\financeiro\CNABController.js"


export default {
  name: "CnabRetorno",
  components: {
  },

  data() {
    return {

      loading             : false,
      loading_excluir     : false,
      loading_arquivo     : false,
      loading_cedente     : false,
      currentPage: 1,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      COR_SUBTITULO           : COR_SUBTITULO,
      formatDate              : formatDate,
      formatPrice             : formatPrice,
      accentColor             : '#f00',
      dialog_excluir          : false,
      dialog_excluir_alert    : false,
      dialog_excluir_msg      : false,
      dialog_excluir_message  : null,
      dialogIndiceCadastro: false,

      dados: {
        banco_selecionado: null,
        cedente_selecionado: null,
        arquivo_selecionado: null,
      },

      //INFO_HEADER
      
      empreend_nome       : null,
      nome_banco          : null,
      agencia_banco       : null,
      nome_cedente        : null, 
      cod_cedente         : null,     

      //TOTAIS RODAPÉ
      total_parcelas      : 0,
      total_pagar         : 0,
      total_pago          : 0,

      arquivo: null,

       /* Mostra no SELECT BANCO */
      opcoes_banco: [],

      /* Mostra no SELECT CEDENTE */
      opcoes_cedente: [],

      /* Mostra no SELECT ARQUIVO */
      opcoes_arquivo: [],

      /* TÍTULO COBRANÇA */
       headers: [
        { text: "Situação", value: "situacao", class: 'title-text' },
        { text: "Ocorrência", value: "ocorrencia", class: 'title-text' },
        { text: "Código", value: "codigo", class: 'title-text' },
        { text: "Via", value: "via", class: 'title-text' },
        { text: "Parcela", value: "parcela", class: 'title-text' },
        { text: "Tipo", value: "tipo", class: 'title-text' },
        { text: "Dt. Vencimento", value: "dt_vencimento", class: 'title-text' },
        { text: "Dt. Pagamento", value: "dt_pagamento", class: 'title-text' },
        { text: "Valor Parcela", value: "valor_parcela", class: 'title-text' },
        { text: "Multa/Juros à pagar", value: "multa_juros_a_pagar", class: 'title-text' },
        { text: "Vl. à pagar", value: "valor_a_pagar", class: 'title-text' },
        { text: "Multa/Juros Pago", value: "multa_juros_pago", class: 'title-text' },
        { text: "Valor Pagamento", value: "valor_pagamento", class: 'title-text' },
        { text: "Resíduo", value: "residuo", class: 'title-text' },
        { text: "Unidade", value: "unidade", class: 'title-text' },
        { text: "N° contrato", value: "num_contrato", class: 'title-text' },
        { text: "Cliente", value: "cliente", class: 'title-text' },
        { text: "Empreendimento", value: "empreendimento", class: 'title-text' },
        { text: "Num. proposta", value: "num_proposta", class: 'title-text' },
        { text: "Nosso número", value: "nosso_numero", class: 'title-text' },
      ],

     /* TABELA COBRANÇAS */
      dados_cobrancas: [],
    };
  },

  async mounted(){
    this.busca_bancos();
  },

  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 229 - 15;
    }
  },

  watch: {
    "dados.banco_selecionado"(val){
      this.dados_cobrancas            = [];
      this.dados.cedente_selecionado  = null;
      this.opcoes_cedente             = [];
      this.dados.arquivo_selecionado  = null;
      this.opcoes_arquivo             = [];
      this.dados_registros            = [];
      this.total_parcelas             = null
      this.total_pagar                = null
      this.total_pago                 = null
      this.nome_banco                 = null;
      this.agencia_banco              = null;
      this.nome_cedente               = null;
      this.empreend_nome              = null;
      this.cod_cedente                = null; 
      this.busca_cedente(val.cod_banco)
    },
    "dados.cedente_selecionado"(val){
      this.dados_cobrancas            = [];
      this.opcoes_arquivo             = [];
      this.total_parcelas             = null
      this.total_pagar                = null
      this.total_pago                 = null
      this.nome_banco                 = null;
      this.agencia_banco              = null;
      this.nome_cedente               = null;
      this.empreend_nome              = null;
      this.cod_cedente                = null; 
      this.busca_arquivo(val.cod_pessoa_conta, val.empreend_boleto_cedente_com_dig, val.cod_banco)
    },   
    "dados.arquivo_selecionado"(val) {
      this.dados_cobrancas            = [];
      this.total_parcelas             = null
      this.total_pagar                = null
      this.total_pago                 = null
      this.nome_banco                 = null;
      this.agencia_banco              = null;
      this.nome_cedente               = null;
      this.empreend_nome              = null;
      this.cod_cedente                = null; 
      this.exibe_arquivo(val.cod_empreendcobrancacnab);
  
      
    }

  },

  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },

    /**
     * Função para buscar bancos cadastrados
     */
    async busca_bancos() {
      const resposta = await API.get("banco", {});

      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          this.opcoes_banco = resposta.data.result;
//console.log('busca_bancos',resposta)
        } else this.opcoes_banco = [];
      }
    },

    /**
     * Função para buscar cedentes ao banco selecionado
     */
    async busca_cedente(cod_banco) {
      this.loading_cedente            = true;
      const resposta = await API.get(`cnab_remessa_cedentes`, {params:{cod_banco: cod_banco}});
//console.log('busca_cedentes', resposta);
      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          const lo_Obj = resposta.data.result.map((e) => ({
                                                        cod_empreendcobrancacnab            : e.cod_empreendcobrancacnab,
                                                        cod_pessoa_conta                    : e.cod_pessoa_conta, 
                                                        cod_banco                           : e.cod_banco, 

                                                        cedente_nome                        : e.pessoa_nome,
                                                        numerocpfcgc                        : e.numerocpfcgc,

                                                        agencia_numero                      : e.agencia_numero,
                                                        agencia_numero_dig                  : e.agencia_numero_dig,
                                                        agencia_numero_com_dig              : e.agencia_numero_com_dig,

                                                        pessoa_conta_numero                 : e.pessoa_conta_numero,
                                                        pessoa_conta_numero_dig             : e.pessoa_conta_numero_dig,
                                                        pessoa_conta_numero_com_dig         : e.pessoa_conta_numero_com_dig,

                                                        empreend_boleto_cedente             : e.empreend_boleto_cedente,
                                                        empreend_boleto_cedente_dv          : e.empreend_boleto_cedente_dv,
                                                        empreend_boleto_cedente_com_dig     : e.empreend_boleto_cedente_com_dig,

                                                        empreend_boleto_convenio            : e.empreend_boleto_convenio,
                                                        empreend_boleto_convenio_dv         : e.empreend_boleto_convenio_dv,
                                                        empreend_boleto_convenio_com_dig    : e.empreend_boleto_convenio_com_dig,

                                                        cedente_1                : e.pessoa_nome + ' | Ag:' + 
                                                                                  e.agencia_numero + ' | Conta:' + 
                                                                                  e.pessoa_conta_numero + ' Cedente:' + 
                                                                                  e.empreend_boleto_cedente
                                            }));
          this.opcoes_cedente             = lo_Obj;
          this.loading_cedente            = false;
        }
        else
          this.opcoes_cedente = [];
      } else this.loading_cedente          = false;
    },

    /**
     * Função para buscar arquivo referentes ao banco e cedente selecionado
     */
    async busca_arquivo(cod_pessoa_conta, empreend_boleto_cedente_com_dig, cod_banco) {
      this.loading_arquivo            = true;
      this.dados_cobrancas            = [];     
      const resposta = await API.get(`cnab_remessa_arquivos`, {params:{ 
                                                                  cod_pessoa_conta        : cod_pessoa_conta, 
                                                                  empreend_boleto_cedente : empreend_boleto_cedente_com_dig, 
                                                                  cod_banco               : cod_banco,
                                                                  tipo                    : 'RET' 
                                                              }});                                                   
      if (resposta) {
        if (resposta.data.result != "Não possui dados") {
          this.opcoes_arquivo = resposta.data.result;
          this.loading_arquivo            = false;
        } else this.opcoes_arquivo        = [];
      } else {
        this.loading_arquivo              = false;
        this.dados.arquivo_selecionado    = null;
      }
    },

    async exibe_arquivo(event) {
      try {
        this.loading = true;
        const resposta = await API.post('cnab_retorno', {cod_empreendcobrancacnab: event})
        if (resposta.data.result.length == []){
          this.loading = false;
          this.store_site.alert_cor       = '#F44336';
          this.store_site.alert_msg       = `Erro ao abrir ${this.arquivo.name}, verifique o arquivo CNAB`;
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert           = true;
          return console.log('ERRO NA LEITURA! Verifique o arquivo. ')

        } if (this.opcoes_arquivo == []) {
            return console.log('SEM ARQUIVO SELECINADO ')
        }
        this.dados_cobrancas = resposta.data.result
//console.log("items",this.dados_cobrancas)

        this.total_parcelas      = 0;
        this.total_pagar         = 0;
        this.total_pago          = 0;
        this.nome_banco          = null;
        this.agencia_banco       = null;
        this.nome_cedente        = null;
        this.empreend_nome       = null;
        this.cod_cedente         = null; 
        var _this = this;
        this.dados_cobrancas.map(function(tot) { 

          if (tot.banco_nome != null){
            _this.nome_banco         = tot.banco_nome;
            _this.agencia_banco      = tot.agencia_numero;
            _this.empreend_nome      = tot.empreend_nome;
            _this.nome_cedente       = tot.prop_nome;
            _this.cod_cedente        = tot.empreend_boleto_cedente
          }
        
          _this.total_parcelas     += tot.parcela_valor;
          _this.total_pagar        += tot.parcela_a_pagar
          _this.total_pago         += tot.pagto_valor;
        })
        this.loading = false;
      }
      catch (error) {}
    }

  }
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif!important;
}

.descricao {
  font-size: 12px;
  padding-left: 5px;
}

.descricao-line {
  margin-bottom: -5px;
  vertical-align: top;
}

table tr {
  width: 100%;
}

.status_dia, 
.status_baixado,
.status_pago-atraso, 
.status_nao-encontrado, 
.status_msg-banco, 
.status_invalido {
  height: 24px;
  display: block!important;
  white-space: nowrap;
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px ;
  border-radius: 25px;
}
.motivo_retorno {
  white-space: nowrap;
}

.residuo {
  color: red;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
.status_legend {
  min-width: 132px;
  font-size: 12px !important;
  font-weight: 400!important;
  padding: 2px 2px 2px 12px ;
}

.status_legend:hover {
  opacity: 0.5;
}

.tipoNumero {
  text-align: end;
  white-space: nowrap;
}

.status_baixado {
  background: #EF5350;
}

.status_pago-atraso {
  background: #FFA726;
}

.status_nao-encontrado {
  background: #7E57C2;
}

.status_msg-banco {
  background: #BDBDBD;
}

.status_invalido {
  background: #F44336;
}

.cliente, .empreendimento, .ocorrencia {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O MW */
.ocorrencia {
  max-width: 20ch;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.icon-help {
  margin-top: -2px;
  color:  #FFF;
  opacity: 0.5;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.inputs_header {
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

/* TABELA */
.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }
}
</style>

