<template>
  <v-container fluid id="CnabRetornoImportacao" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title
        class="ml-4 pa-0 d-flex flex-column "
        dark
        elevation="0"
      >
        <div class="d-flex align-center justify-space-between " tabindex="-1">
          <div class="d-flex align-center" style="width:100%;">
            <v-col cols="12" sm="6" class="pt-2 px-2 pl-0 pb-0">
              <v-file-input 
              v-model="arquivo"
              accept=".txt, .RET"
              prepend-icon="mdi-paperclip"
              small-chips
              flat
              dark
              dense
              solo-inverted
              class="inputs_header"
              label="Selecione o arquivo a ser baixado">
              </v-file-input>
            </v-col>
            <div v-show="empreend_nome != null">
              <v-btn class="text-white  text-capitalize mr-2" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
                <v-icon class="btn-icon mr-1" color="light-blue accent-2">
                  mdi-file-download-outline
                </v-icon>
                <span>Baixar</span>
              </v-btn>
            </div>
            <div v-show="empreend_nome" class="text-white  text-capitalize descricao" :color="COR_PRINCIPAL" elevation="0" :small="isMobile">
              <p class= "descricao-line">Empreendimento: {{empreend_nome}}</p>
              <p class= "descricao-line"><span>Banco: {{nome_banco}}</span><span> - Ag: {{agencia_banco}}</span></p>
              <p class= "descricao-line">Cedente: {{cod_cedente}} - {{nome_cedente}}</p>
            </div>
          </div>
        </div>
      </v-toolbar-title>
   
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0 ">
        <v-container class="container-principal container-rounded rounded-lg tabela mx-0 px-0">
          <v-card elevation="0" class="pb-0">
            <v-data-table
            
            :headers="headers"
            :items="dados_cobrancas"
            :loading="loading"
            :items-per-page="-1"
            :height="tableHeight"
            fixed-header
            dense
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            >
              <template v-slot:header>
                  <thead>
                    <tr>
                      <th v-for="h in headers" :key="h.value" :class="h.class" class="pt-0 pb-3">
                        <span>{{h.text}}</span>
                      </th>
                    </tr>
                  </thead>
              </template>

              <template #item="{ item }">
                <tr :style="{'background-color': item.residuo > 0 ? '#FFD0D0' : '#FFFFFF'}" v-show="item.codigo_movimento_retorno != '228'">
                  <td>
                  <!-- Situação -->
                    <v-tooltip top
                      v-if="item.situacao == 'Dia'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_dia">Dia<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que serão baixadas em dia</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Em Atraso'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_pago-atraso">Pago atraso<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que serão baixadas em atraso</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Baixado'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_baixado">Baixado<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças que já estão baixadas no sistema</span>
                    </v-tooltip> 
                    <v-tooltip top
                      v-if="item.situacao == 'Não Encontrado'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_nao-encontrado">Não encontrado<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças localizadas no arquivo CNAB, mas não encontradas no sistema</span>
                    </v-tooltip> 
                    <v-tooltip top
                      v-if="item.situacao == 'Inválido' || item.situacao == 'Inval. Antecip' || item.situacao == 'Invál. Rescindi'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_invalido">Inválido<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças inválidas por motivo de erro de leitura, arquivo inválido e/ou dados não permitidos para a cobrança</span>
                    </v-tooltip>
                    <v-tooltip top
                      v-if="item.situacao == 'Msg. do Banco'">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_msg-banco">Msg do banco<v-icon class="float-right icon-help">mdi-help-circle-outline</v-icon></span>
                      </template>
                      <span>Cobranças localizadas no arquivo CNAB como mensagens de operações bancárias</span>
                    </v-tooltip>
                  </td>

                  <!-- Motivo Retorno -->
                  <td>
                    <span class="motivo_retorno">{{ item.codigo_movimento_retorno_descr }}</span>
                  </td>

                  <!-- Código -->
                  <td class="tipoNumero">
                    {{ item.cod_empreendcobranca }}
                  </td>

                  <!-- Via -->
                  <td class="tipoNumero">
                    {{ item.empreendcobr_impressao_via }}
                  </td>

                  <!-- Parcela -->
                  <td >
                    {{ item.parcinicial_parcfinal }}
                  </td>

                  <!-- Tipo -->
                  <td>
                    {{ item.parcela_tipo }}
                  </td>

                  <!-- Dt. Vencimento -->
                  <td>
                    {{ formatDate(item.parcela_dt) }}
                  </td>

                  <!-- Dt. Pagamento -->
                  <td>
                    {{ item.codigo_movimento_retorno == '02' ? '' : formatDate(item.pagto_dt) }}
                  </td>

                  <!-- Valor Parcela -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.parcela_valor) }}
                  </td>

                  <!-- Multa/Juros à pagar -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.multa_juros) }}
                  </td>

                  <!-- Vl à Pagar -->
                  <td class="tipoNumero">
                    {{ formatPrice(item.parcela_a_pagar) }}
                  </td>

                  <!-- Multa/Juros Pago -->
                  <td class="tipoNumero">
                    {{item.codigo_movimento_retorno == '02' ? '' :  formatPrice(item.pagto_valor_multajuros) }}
                  </td>

                  <!-- Valor Pagamento -->
                  <td class="tipoNumero">
                    {{ item.codigo_movimento_retorno == '02' ? '' :  formatPrice(item.pagto_valor) }}
                  </td>

                  <!-- Resíduo -->
                  <td class="tipoNumero residuo">
                    <span class="status_cancelado">
                      {{ item.codigo_movimento_retorno == '02' || item.residuo == null ? '' : formatPrice(item.residuo) }}
                    </span> 
                  </td>

                  <!-- Unidade -->
                  <td>
                    {{ item.unidade }}
                  </td>

                  <!-- N° Contrato -->
                  <td class="tipoNumero">
                    {{ item.empreendvenda_contrato }}
                  </td>

                  <!-- Cliente -->
                  <td>
                    <span class="cliente">{{ item.pessoa_nome }}</span>
                  </td>

                  <!-- Empreendimento -->
                  <td>
                    <span class="empreendimento">{{ item.empreend_nome }}</span>
                  </td>

                  <!-- Num. Proposta -->
                  <td class="tipoNumero">
                    {{ item.cod_empreendvenda}}
                  </td>

                  <!-- Nosso Numero -->
                  <td class="tipoNumero">
                    {{ item.titulo_nossonumero }}
                  </td>
                </tr> 
              </template>
            </v-data-table>
            <!-- FIM TABELA COBRANÇAS -------------------------------------------------------->

            <!-- RODAPE COBRANÇAS -------------------------------------------------------->
            <div class="my-2 ml-4">
              <table class="d-flex justify-end">
                <tbody class="" style="width: 65%">
                  <tr class="text sticky-table-footer-desktop">
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total Parcelas:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_parcelas)}}</span>
                    </td>
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total à Pagar:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_pagar)}}</span>
                    </td>
                    <td align="left" style="width: 15%; background-color:#FFFFFF">
                      <span class="text-left font-weight-bold mr-1 body-2">Total Pago:</span>
                      <span class="font-weight-medium body-2">R$ {{formatPrice(total_pago)}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

            <!-- LEGENDA COBRANÇAS -------------------------------------------------------->
            <!-- <div class="mt-2 mb-1 ml-4">
              <table class="d-flex justify-start">
                <tbody class="">
                  <tr class="text sticky-table-footer-desktop d-flex mb-2">
                    <td align="left" style="display: flex">
                      <span class="text-left body-2 font-weight-bold mr-3" v-bind:style="{ 'color':COR_SUBTITULO }">Legenda:</span>
                    </td>

                    <td align="left" style="display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_dia">Dia<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que serão baixadas em dia</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_pago-atraso">Pago atraso<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que serão baixadas em atraso</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_baixado">Baixado<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças que já estão baixadas no sistema</span>
                      </v-tooltip> 
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_nao-encontrado">Não encontrado<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças localizadas no arquivo CNAB, mas não encontradas no sistema</span>
                      </v-tooltip> 
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_invalido">Inválido<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças inválidas por motivo de erro de leitura, arquivo inválido e/ou dados não permitidos para a cobrança</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left caption font-weight-bold mr-1 status_legend status_msg-banco">Msg do banco<v-icon class="icon-help ml-1">mdi-help-circle-outline</v-icon></span>
                        </template>
                        <span>Cobranças localizadas no arquivo CNAB como mensagens de operações bancárias</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div> -->

            </v-card>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->


  </v-container>
  
</template>

<script>
import store_site from "../../../store/store_site";
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import { formatDate, formatPrice } from "../../../services/funcoes";
import { API } from "../../../services/API";

export default {
  name: "CnabRetornoImportacao",
  components: {
  },

  data() {
    return {
      store_site          : store_site,

      loading             : false,
      loading_excluir     : false,
      currentPage: 1,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      COR_SUBTITULO           : COR_SUBTITULO,
      formatDate              : formatDate,
      formatPrice             : formatPrice,
      accentColor             : '#f00',
      dialog_excluir          : false,
      dialog_excluir_alert    : false,
      dialog_excluir_msg      : false,
      dialog_excluir_message  : null,
      dialogIndiceCadastro: false,

      //INFO_HEADER
      
      empreend_nome       : null,
      nome_banco          : null,
      agencia_banco       : null,
      nome_cedente        : null, 
      cod_cedente         : null,     

      //TOTAIS RODAPÉ
      total_parcelas      : 0,
      total_pagar         : 0,
      total_pago          : 0,

      arquivo: null,

      /* TÍTULO COBRANÇA */
       headers: [
        { text: "Situação", value: "situacao", class: 'title-text' },
        { text: "Motivo Retorno", value: "codigo_movimento_retorno_descr", class: 'title-text' },
        { text: "Código", value: "codigo", class: 'title-text' },
        { text: "Via", value: "via", class: 'title-text' },
        { text: "Parcela", value: "parcela", class: 'title-text' },
        { text: "Tipo", value: "tipo", class: 'title-text' },
        { text: "Dt. Vencimento", value: "dt_vencimento", class: 'title-text' },
        { text: "Dt. Pagamento", value: "dt_pagamento", class: 'title-text' },
        { text: "Valor Parcela", value: "valor_parcela", class: 'title-text' },
        { text: "Multa/Juros à pagar", value: "multa_juros_a_pagar", class: 'title-text' },
        { text: "Vl. à pagar", value: "valor_a_pagar", class: 'title-text' },
        { text: "Multa/Juros Pago", value: "multa_juros_pago", class: 'title-text' },
        { text: "Valor Pagamento", value: "valor_pagamento", class: 'title-text' },
        { text: "Resíduo", value: "residuo", class: 'title-text' },
        { text: "Unidade", value: "unidade", class: 'title-text' },
        { text: "N° contrato", value: "num_contrato", class: 'title-text' },
        { text: "Cliente", value: "cliente", class: 'title-text' },
        { text: "Empreendimento", value: "empreendimento", class: 'title-text' },
        { text: "Num. proposta", value: "num_proposta", class: 'title-text' },
        { text: "Nosso número", value: "nosso_numero", class: 'title-text' },
      ],

     /* TABELA COBRANÇAS */
      dados_cobrancas: []
    };
  },

  async mounted(){
    
  },

  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 229 - 15;
    }
  },

  watch: {
    arquivo() {
      this.dados_cobrancas              = [],
      this.empreend_nome                = null,
      this.nome_banco                   = null,
      this.agencia_banco                = null,
      this.nome_cedente                 = null,
      this.cod_cedente                  = null, 
      this.total_parcelas               = 0,
      this.total_pagar                  = 0,
      this.total_pago                   = 0,
      this.exibe_arquivo();
    }
  },

  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },

    async exibe_arquivo(event) {
      var reader = new FileReader()
      //console.log('arquivo',this.arquivo)
      ////console.log('/*/*/*/*/*/*/*/*/*/*',reader)
      
      try {
        reader.readAsDataURL(this.arquivo)
        ////console.log('reader ===================',reader.readAsDataURL(this.arquivo))
        reader.onload = async () => {
        //console.log('passou aqui onload')
        this.loading = true;
        const resposta = await API.post('cnab_retorno', {arquivo: reader.result})
        const resposta2 = true
        //console.log('resposta',resposta)
        if(resposta.status > 200){
          this.loading = false;
          this.store_site.alert_cor       = '#FFA500';
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert           = true;
          if (resposta.data.result[0] ==  'Arquivo sem conteúdo'){
            this.store_site.alert_msg       = `Arquivo ${this.arquivo.name} sem conteúdo, verifique o arquivo CNAB`;
            return //console.log('SEGMENTOS NÃO ENCONTRADOS! Verifique o arquivo. ')
          }
          else if (resposta.data.result[0] == 'Arquivo vazio'){
            //console.log('vazio')
            this.store_site.alert_msg       = `Arquivo ${this.arquivo.name} vazio, verifique o arquivo CNAB`;
            return //console.log('ARQUIVO VAZIO! Verifique o arquivo. ')
          } 
          else if (resposta.data.result[0] ==  'banco não encontrado'){
            this.store_site.alert_msg       = `Código do banco não encontrado no arquivo ${this.arquivo.name}, verifique o arquivo CNAB`;
            return //console.log('BANCO INEXISTENTE! Verifique o arquivo. ')
          }
          else if (resposta.data.result[0] ==  'Banco ou Cedente não encontrado para  este arquivo'){
            this.store_site.alert_msg       = `Código do banco não encontrado no arquivo ${this.arquivo.name}, verifique o arquivo CNAB`;
            return //console.log('Dados de Banco ou Cedente para este empreendimento não foram encontrados. ! Verifique o arquivo. ')
          }
          else if (resposta.data.result[0] ==  "Numero de arquivo já importado"){
            this.store_site.alert_cor       = COR_PRINCIPAL;
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert           = true;
            this.store_site.alert_msg       = `Arquivo ${this.arquivo.name} já baixado, verifique o arquivo CNAB`;
            return //console.log('Arquivo já importado, não é possível baixar! Verifique o arquivo. ')
          }
        }
        this.dados_cobrancas = resposta.data.result
//console.log("items",this.dados_cobrancas)

          this.total_parcelas      = 0;
          this.total_pagar         = 0;
          this.total_pago          = 0;
          this.nome_banco          = null;
          this.agencia_banco       = null;
          this.nome_cedente        = null;
          this.empreend_nome       = null;
          this.cod_cedente         = null; 
          var _this                = this;
          this.dados_cobrancas.map(function(tot) { 

          if (tot.banco_nome != null){
              _this.nome_banco         = tot.banco_nome;
              _this.agencia_banco      = tot.agencia_numero;
              _this.empreend_nome      = tot.empreend_nome;
              _this.nome_cedente       = tot.prop_nome;
              _this.cod_cedente        = tot.empreend_boleto_cedente
            }
          
            _this.total_parcelas     += tot.parcela_valor;
            _this.total_pagar        += tot.parcela_a_pagar
            _this.total_pago         += tot.pagto_valor;
          
            
          })  
          this.loading = false;
        }; 
      } catch (error) {}
    } 
  },

};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif!important;
}

.descricao {
  font-size: 12px;
  padding-left: 5px;
}

.descricao-line {
  margin-bottom: -5px;
  vertical-align: top;
}

table tr {
  width: 100%;
}

.status_dia, 
.status_baixado,
.status_pago-atraso, 
.status_nao-encontrado, 
.status_msg-banco, 
.status_invalido {
  height: 24px;
  display: block!important;
  white-space: nowrap;
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px ;
  border-radius: 25px;
}
.motivo_retorno {
  white-space: nowrap;
}

.residuo {
  color: red;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
.status_legend {
  min-width: 132px;
  font-size: 12px !important;
  font-weight: 400!important;
  padding: 2px 2px 2px 12px ;
}

.status_legend:hover {
  opacity: 0.5;
}

.tipoNumero {
  text-align: end;
  white-space: nowrap;
}

.status_baixado {
  background: #EF5350;
}

.status_pago-atraso {
  background: #FFA726;
}

.status_nao-encontrado {
  background: #7E57C2;
}

.status_msg-banco {
  background: #BDBDBD;
}

.status_invalido {
  background: #F44336;
}

.cliente, .empreendimento, .ocorrencia {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

/* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O MW */
.ocorrencia {
  max-width: 20ch;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.icon-help {
  margin-top: -2px;
  color:  #FFF;
  opacity: 0.5;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.inputs_header {
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

/* TABELA */
.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #FFF !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }
}
</style>

